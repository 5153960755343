.field {
  margin-bottom: 2rem;
}

.field-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: var(--font-size-label);
}

.field-label .label-text {
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.field-label .extra-labels,
.subfield-label .extra-labels {
  margin-left: 0.5em;
  color: var(--tertiary-color);
  font-size: 0.9em;
}

[dir="rtl"] .field-label .extra-labels,
[dir="rtl"] .subfield-label .extra-labels {
  margin-right: 0.5em;
  margin-left: inherit;
}

/** Style the extra labels to have commas and brackets */
/*.label .extra-labels::before {
  content: "(";
}

.label .extra-labels::after {
  content: ")";
}*/

/*.label .extra-labels .extra-label::after {
  content: ", ";
}*/

.label .extra-labels .extra-label:last-child::after {
  content: unset;
}

/** Field's input region and input styles */
.field-input {
  position: relative;
}

.field-input input[type="color"],
.field-input input[type="date"],
.field-input input[type="datetime-local"],
.field-input input[type="email"],
.field-input input[type="file"],
.field-input input[type="month"],
.field-input input[type="number"],
.field-input input[type="password"],
.field-input input[type="search"],
.field-input input[type="tel"],
.field-input input[type="text"],
.field-input input[type="time"],
.field-input input[type="url"],
.field-input input[type="week"],
.field-input select,
.field-input textarea,
.field-input .input-select,
.field-input .input-select-custom {
  display: block;
  flex: 1 1 auto;
  width: 100%;
}

.field-input .clear-input {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 100%;
}

[dir="rtl"] .field-input .clear-input {
  right: auto;
  left: 0;
}

.field-errors {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  list-style-type: none;
}

.field-error {
  display: block;
  margin: 0.5rem 0 0;
  padding: 0;
  color: var(--error-color);
}

/** Modifiers */
.field.has-icon .field-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  padding: 0 0.5rem;
  pointer-events: none;
  touch-action: none;
}

[dir="rtl"] .field.has-icon .field-icon {
  left: inherit;
}

.field.has-icon .field-input input[type="color"],
.field.has-icon .field-input input[type="date"],
.field.has-icon .field-input input[type="datetime-local"],
.field.has-icon .field-input input[type="email"],
.field.has-icon .field-input input[type="file"],
.field.has-icon .field-input input[type="month"],
.field.has-icon .field-input input[type="number"],
.field.has-icon .field-input input[type="password"],
.field.has-icon .field-input input[type="search"],
.field.has-icon .field-input input[type="tel"],
.field.has-icon .field-input input[type="text"],
.field.has-icon .field-input input[type="time"],
.field.has-icon .field-input input[type="url"],
.field.has-icon .field-input input[type="week"],
.field.has-icon .field-input select,
.field.has-icon .field-input textarea,
.field.has-icon .field-input .input-select-custom__value-container {
  padding-left: 2rem;
}

[dir="rtl"] .field.has-icon .field-input input[type="color"],
[dir="rtl"] .field.has-icon .field-input input[type="date"],
[dir="rtl"] .field.has-icon .field-input input[type="datetime-local"],
[dir="rtl"] .field.has-icon .field-input input[type="email"],
[dir="rtl"] .field.has-icon .field-input input[type="file"],
[dir="rtl"] .field.has-icon .field-input input[type="month"],
[dir="rtl"] .field.has-icon .field-input input[type="number"],
[dir="rtl"] .field.has-icon .field-input input[type="password"],
[dir="rtl"] .field.has-icon .field-input input[type="search"],
[dir="rtl"] .field.has-icon .field-input input[type="tel"],
[dir="rtl"] .field.has-icon .field-input input[type="text"],
[dir="rtl"] .field.has-icon .field-input input[type="time"],
[dir="rtl"] .field.has-icon .field-input input[type="url"],
[dir="rtl"] .field.has-icon .field-input input[type="week"],
[dir="rtl"] .field.has-icon .field-input select,
[dir="rtl"] .field.has-icon .field-input textarea,
[dir="rtl"] .field.has-icon .field-input .input-select-custom__value-container {
  padding-right: 2rem;
  padding-left: 0;
}

/** Move right side to account for the icon when direction is Rtl */
[dir="rtl"] .field.has-icon.has-label:not(.is-toggleable) .field-label,
[dir="rtl"] .field.has-icon.has-label.is-focused:not(.is-toggleable) .field-label,
[dir="rtl"] .field.has-icon.has-label.has-value:not(.is-toggleable) .field-label {
  padding-right: 2rem;
  padding-left: 0;
}

/** Position toggleable (checkbox/radio) fields elements differently */
.field.is-toggleable .field-icon {
  display: none;
}

.field.is-toggleable .field-control {
  display: flex;
  flex-direction: row;
}

.field.is-toggleable .field-input {
  display: flex;
  flex: 0 0 3rem;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  order: 1;
}

.field.is-toggleable .field-label {
  flex: 1 1 auto;
  order: 2;
}

/** Hide the extra labels on toggleable fields */
.field.is-toggleable .field-label .extra-labels {
  /** .sr-only */
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

.field.is-toggleable .field-errors {
  flex: 1 1 auto;
  order: 3;
}
