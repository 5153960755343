.notification {
  --text-color: var(--light-text-color);
  --background-color: var(--light-background-color);
  --border-color: var(--light-border-color);

  position: relative;
  display: block;
  margin: 0 0 1rem;
  padding: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
  border: solid 1px var(--border-color);
}

.notification-title {
  margin: 0 0 1rem;
}

.notification-extra-content {
  margin: 1rem 0 0;
}

.notification-extra-content .trace-token,
.notification-retrying {
  margin-top: 0.5rem;
  font-size: 0.8em;
}

.notification-retrying .loading-indicator .icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

[dir="rtl"] .notification-retrying .loading-indicator .icon {
  margin-right: 0;
  margin-left: 0.3em;
}

.notification-extra-content .button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}

.notification .button-group {
  align-content: stretch;
  justify-content: stretch;
}

.notification .button-group .button {
  flex: 1 1 auto;
}

.notification-type-success,
.notification-type-warning,
.notification-type-error,
.notification-type-info {
  padding: 1rem 1rem 1rem 3rem;
}

[dir="rtl"] .notification {
  padding-right: 3rem;
  padding-left: 1rem;
}

.notification-type-success::before,
.notification-type-warning::before,
.notification-type-error::before,
.notification-type-info::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.75rem;
  overflow: hidden;
  background: transparent none no-repeat center center;
  background-size: contain;
  content: "";
}

[dir="rtl"] .notification-type-success::before,
[dir="rtl"] .notification-type-warning::before,
[dir="rtl"] .notification-type-error::before,
[dir="rtl"] .notification-type-info::before {
  right: 0;
}

.notification-type-success {
  --text-color: var(--success-text-color);
  --background-color: var(--success-background-color);
  --border-color: var(--success-border-color);
}

.notification-type-success::before {
  background-image: var(--icon-light-success-url);
}

.notification-type-error {
  --text-color: var(--error-text-color);
  --background-color: var(--error-background-color);
  --border-color: var(--error-border-color);
}

.notification-type-error::before {
  background-image: var(--icon-light-error-url);
}

.notification-type-warning {
  --text-color: var(--warning-text-color);
  --background-color: var(--warning-background-color);
  --border-color: var(--warning-border-color);
}

.notification-type-warning::before {
  background-image: var(--icon-dark-warning-url);
}

.notification-type-info {
  --text-color: var(--info-text-color);
  --background-color: var(--info-background-color);
  --border-color: var(--info-border-color);
}

.notification-type-info::before {
  background-image: var(--icon-light-info-url);
}

.notification .button-shape-link .button-label,
.notification .button-shape-link {
  text-decoration: none;
}

.notification-time {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  font-size: 0.8em;
  line-height: 1rem;
  text-align: right;
  opacity: 0.5;
}

[dir="rtl"] .notification-time {
  right: auto;
  left: 0;
}

.notification .button.button-role-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
}

[dir="rtl"] .notification .button.button-role-close {
  right: auto;
  left: 0;
}

.notification .button.button-role-close .button-label {
  /** .sr-only */
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

.notification .button.button-role-close .icon.icon-name-close {
  background-image: var(--icon-light-cancel-url);
}

.notification .button.button-role-close + .notification-time {
  display: none;
}
.notification-body + .button-role-display {
  text-align: left;
}
.notification-body + .button-role-display .button-label {
  white-space: normal;
  text-align: left;
}

/** Alter button theme */
.notification-type-success .button.button-shape-solid.button-theme-primary,
.notification-type-error .button.button-shape-solid.button-theme-primary,
.notification-type-info .button.button-shape-solid.button-theme-primary {
  color: var(--dark-color);
  background-color: var(--light-color);
  border-color: var(--light-color);
}

.notification-type-warning .button.button-shape-solid.button-theme-primary {
  color: var(--light-color);
  background-color: var(--dark-color);
  border-color: var(--dark-color);
}

.notification-type-success .button.button-shape-solid.button-theme-secondary,
.notification-type-error .button.button-shape-solid.button-theme-secondary,
.notification-type-info .button.button-shape-solid.button-theme-secondary {
  color: var(--light-color);
  background-color: transparent;
  border-color: var(--light-color);
}

.notification-type-warning .button.button-shape-solid.button-theme-secondary {
  color: var(--dark-color);
  background-color: transparent;
  border-color: var(--dark-color);
}

@media screen and (min-width: 600px) {
  .notification .button-group {
    align-content: flex-start;
    justify-content: flex-start;
  }

  .notification .button-group .button {
    flex: 0 0 auto;
  }
}
