.actions-list,
.actions-list-item {
  display: block;
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  list-style-type: none;
}

.actions-list {
  /*display: flex;*/
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
