.language-switcher {
  --size-modifier: var(--size-modifier-m);
  --text-size-modifier: var(--size-modifier-m);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  padding: 0 0 0 calc(1rem * var(--size-modifier));
}

/** Contract the language switcher if it is in the `.app-header` */
@media screen and (max-width: 500px) {
  .app-header .language-switcher {
    position: relative;
    max-width: 3rem;
    padding: 0 calc(1rem * var(--size-modifier));
  }

  .app-header .language-switcher .input-select,
  .app-header .language-switcher select {
    position: absolute;
    top: 0;
    left: 0;
  }

  .app-header .language-switcher .input-select {
    width: 100%;
    height: 100%;
  }

  .app-header .language-switcher select {
    z-index: 1;
    opacity: 0;
  }

  .app-header .language-switcher .input-select-arrow {
    position: absolute;
    top: 1rem;
    right: auto;
    left: 50%;
    display: block;
    width: 100%;
    height: 1rem;
    margin-left: -50%;
    padding: 0;
    text-align: center;
  }
}
