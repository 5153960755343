.modal.terms-and-conditions {
  min-width: var(--width-readable);
}

.modal.terms-and-conditions .terms-and-conditions-header {
  position: relative;
}

.modal.terms-and-conditions .terms-and-conditions-title {
  margin-top: 0;
}

.modal.terms-and-conditions .terms-and-conditions-header .button.button-role-close {
  position: absolute;
  top: 0;
  padding: 0;
}

[dir="ltr"] .modal.terms-and-conditions .terms-and-conditions-header .button.button-role-close {
  right: 0;
}

[dir="rtl"] .modal.terms-and-conditions .terms-and-conditions-header .button.button-role-close {
  left: 0;
}

/** Name field localisation */
[lang="ja"][data-store-country="JP"].configurable-form .group.customer-name .field-name-honorificPrefix {
  order: 0;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-name .field-name-familyName {
  order: 1;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-name .field-name-givenName {
  order: 2;
}

/** Address field localisation */
[data-customer-country="JP"].configurable-form .group.customer-address .field-name-postalCode {
  order: 0;
}

[data-customer-country="JP"].configurable-form .group.customer-address .field-name-addressRegion {
  order: 1;
}

[data-customer-country="JP"].configurable-form .group.customer-address .field-name-addressLocality {
  order: 2;
}

[data-customer-country="JP"].configurable-form .group.customer-address .field-name-streetAddress1 {
  order: 3;
}

[data-customer-country="JP"].configurable-form .group.customer-address .field-name-streetAddress2 {
  order: 4;
}

[data-customer-country="JP"].configurable-form .group.customer-address .field-name-streetAddress3 {
  order: 5;
}
/** Additional info fields localisation */
[lang="ja"][data-store-country="JP"].configurable-form .group.customer-additional-name .field-name-honorificPrefix {
  order: 0;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-additional-name .field-name-familyName {
  order: 1;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-additional-name .field-name-givenName {
  order: 2;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-additional-name .field-name-alternateFamilyName1 {
  order: 3;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-additional-name .field-name-alternateGivenName1 {
  order: 4;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-additional-name .field-name-alternateFamilyName2 {
  order: 5;
}

[lang="ja"][data-store-country="JP"].configurable-form .group.customer-additional-name .field-name-alternateGivenName2 {
  order: 6;
}
